export default function() {
  for (let sideNote of document.querySelectorAll('.js-side-note')) {
    let id = +sideNote.id.match(/\d+/)[0];
    sideNote.addEventListener('click', () => toggleSideNote(id, sideNote.classList[0]))
  }
}
function toggleSideNote(id, base) {
  let trigger = document.querySelector(`#sidenote-trigger-${id}`);
  let text = document.querySelector(`#sidenote-text-${id}`);
  let end = base.substring(base.length - 4);
  let rest = base.slice(0, base.length - 5);

  if (end === 'text') {
    trigger.classList.toggle(`${rest}_is-clicked`);
    text.classList.toggle(`${base}_is-clicked`);
  } else {
    trigger.classList.toggle(`${base}_is-clicked`);
    text.classList.toggle(`${base}-text_is-clicked`);
  }
}
