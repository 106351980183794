export default function() {
  let progressBar = document.querySelector('.js-progress-bar');
  let toc = document.querySelector('.js-expandable-toc');

  function hide() {
    progressBar.classList.remove('progress-bar_is-visible');
  }

  function show() {
    progressBar.classList.add('progress-bar_is-visible');
  }

  function expand() {
    toc.classList.add('table-of-contents_is-expanded');
  }

  function close() {
    toc.classList.remove('table-of-contents_is-expanded');
  }

  function setupScroll() {
    document.addEventListener('scroll', () => {
      let article = document.querySelector('.js-content-article');

      if (article.getBoundingClientRect().top < 100) {
        show();
        let totalHeight = article.offsetHeight + window.innerHeight;
        let progress = (totalHeight - (article.getBoundingClientRect().top + article.offsetHeight)) / totalHeight;
        progressBar.style.setProperty('--progress', `${progress * 100}%`);
      } else {
        hide();
      }
    });
  }


  function setupToc() {
    document.querySelector('.js-open-toc-btn').addEventListener('click', expand);
    document.querySelector('.js-close-toc-btn').addEventListener('click', close);
  }

  setupScroll();
  setupToc();
}
