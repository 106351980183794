export default function() {
  for (let footNote of document.querySelectorAll('.js-foot-note')) {
    let id = +footNote.id.match(/\d+/)[0];
    footNote.addEventListener('click', () => toggleFootNote(id, footNote.classList[0]))
  }
}
function toggleFootNote(id, base) {
  let trigger = document.querySelector(`#footnote-trigger-${id}`);
  let text = document.querySelector(`#footnote-text-${id}`);
  let end = base.substring(base.length - 4);
  let rest = base.slice(0, base.length - 5);

  if (end === 'text') {
    trigger.classList.toggle(`${rest}_is-clicked`);
    text.classList.toggle(`${base}_is-clicked`);
  } else {
    trigger.classList.toggle(`${base}_is-clicked`);
    text.classList.toggle(`${base}-text_is-clicked`);
  }
}
