let navUserAgent = navigator.userAgent;
let isSafari  = false;
let version  = '' + parseFloat(navigator.appVersion);
let tempVersionOffset, tempVersion;
let isIE = /*@cc_on!@*/false || !!document.documentMode;

if ((tempVersionOffset = navUserAgent.indexOf('Safari')) != -1) {
  isSafari = true;
  version = navUserAgent.substring(tempVersionOffset + 7);

  if ((tempVersionOffset = navUserAgent.indexOf('Version')) != -1) {
    version = navUserAgent.substring(tempVersionOffset + 8);
  }

  if ((tempVersion = version.indexOf(' ')) != -1) {
    version = version.substring(0, tempVersion);
  }
}

export function warnOldBrowser() {
  if (isIE || (isSafari && +version.split('.')[0] <= 10)) {
    alert('الرجاء تحديث المتصفّح لاستخدام الموقع بشكلٍ أفضل.\nPlease update your browser for a better website experience.');
  }
}
