export default function () {
    let tags = document.querySelector('.js-bottom-tags');
    let stickyBtn = document.querySelector('.oped .donate-btn');
    let fixedBtn = document.querySelector(
      '.oped .long-content__latest .donate-btn'
    );
  
    document.addEventListener('scroll', handleScroll);
  
    function handleScroll() {
      let top = tags.getBoundingClientRect().top;
  
      if (top < 0) {
        tags.classList.add('side-content__duplicated-tags_is-shown');
      } else if (top > 100) {
        tags.classList.remove('side-content__duplicated-tags_is-shown');
      }
      handleDonateBtnVisibility();
    }
  
    function handleDonateBtnVisibility() {
      isLower(stickyBtn, fixedBtn)
        ? (function () {
            stickyBtn.style.opacity = 0;
            fixedBtn.style.opacity = 100;
          })()
        : (function () {
            stickyBtn.style.opacity = 100;
            fixedBtn.style.opacity = 0;
          })();
    }
  }
  
  function isLower(elem1, elem2) {
    let clientHeight =
      window.innerHeight || document.documentElement.clientHeight;
    let elem1DeltaTop = clientHeight - elem1.getBoundingClientRect().top;
    let elem2DeltaTop = clientHeight - elem2.getBoundingClientRect().top;
  
    return elem1DeltaTop < elem2DeltaTop;
  }