export default function() {
  function windowClick(e) {
    if (!e.target.closest('.js-menu') && !e.target.closest('.js-header-menu-btn')) {
      close();
    }
  }

  function close() {
    document.body.classList.remove('is-menu-open');
    window.removeEventListener('click', windowClick);
  }

  function open() {
    document.body.classList.add('is-menu-open');
    window.addEventListener('click', windowClick);
  }

  document.querySelector('.js-header-menu-btn').addEventListener('click', () => {
    let isOpen = document.body.classList.contains('is-menu-open');
    isOpen ? close() : open();
  });
}
