import flatpickr from 'flatpickr';
import { Arabic } from 'flatpickr/dist/l10n/ar.js';

// Custom Arabic localization adjustments
Arabic.months.longhand = [
  'كانون الثاني', 'شباط', 'آذار', 'نيسان', 'أيّار', 'حزيران',
  'تمّوز', 'آب', 'أيلول', 'تشرين الأول', 'تشرين الثاني', 'كانون الأول',
];
Arabic.weekdays.shorthand = Arabic.weekdays.longhand;
Arabic.firstDayOfWeek = 1;

export default function () {
  let pickerDesktop = document.querySelector('.js-date-picker-desktop');
  let pickerMobile = document.querySelector('.js-date-picker-mobile');
  let inputDesktop = pickerDesktop.querySelector('.js-date-picker-input');
  let inputMobile = pickerMobile.querySelector('.js-date-picker-input');
  let clearDesktop = pickerDesktop.querySelector('.js-date-picker-clear');
  let clearMobile = pickerMobile.querySelector('.js-date-picker-clear');

  function checkClearVisibility(input, clearButton) {
    if (input.value.trim() === '') {
      clearButton.classList.remove('search__filter-clear_is-visible');
    } else {
      clearButton.classList.add('search__filter-clear_is-visible');
    }
  }

  function syncDates(source, target) {
    if (target.value !== source.value) {
      target.value = source.value;
    }
  }

  // Initialize flatpickr for both inputs
  let instanceDesktop = flatpickr(inputDesktop, {
    locale: Arabic,
    mode: 'range',
    dateFormat: 'd-m-Y',
    maxDate: new Date(),
    onChange: function() {
      checkClearVisibility(inputDesktop, clearDesktop);
      syncDates(inputDesktop, inputMobile); // Sync desktop to mobile
    },
  });

  let instanceMobile = flatpickr(inputMobile, {
    locale: Arabic,
    mode: 'range',
    dateFormat: 'd-m-Y',
    maxDate: new Date(),
    onChange: function() {
      checkClearVisibility(inputMobile, clearMobile);
      syncDates(inputMobile, inputDesktop); // Sync mobile to desktop
    },
  });

  // Check initial visibility of clear buttons
  checkClearVisibility(inputDesktop, clearDesktop);
  checkClearVisibility(inputMobile, clearMobile);

  // Add event listeners to clear buttons
  clearDesktop.addEventListener('click', function() {
    instanceDesktop.clear();
    checkClearVisibility(inputDesktop, clearDesktop);
  });
  clearMobile.addEventListener('click', function() {
    instanceMobile.clear();
    checkClearVisibility(inputMobile, clearMobile);
  });
}
