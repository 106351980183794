export default function() {
  let hasAccepted = getCookie('accepted');
  let cookiesEl = document.querySelector('.js-cookies');

  function open() {
    cookiesEl.style.display = 'block';
    document.querySelector('.js-close-cookies').addEventListener('click', close);
    document.querySelector('.js-accept-cookies').addEventListener('click', accept);
  }

  function accept() {
    setCookie('accepted', 'true', 2000);
    close();
    document.querySelector('.js-close-cookies').removeEventListener('click', close);
    document.querySelector('.js-accept-cookies').removeEventListener('click', accept);
  }

  function close() {
    cookiesEl.style.display = 'none';
  }

  if (!hasAccepted || hasAccepted !== 'true') {
    open()
  }
}

function setCookie(name, value, days = 7, path = '/') {
  let expires = new Date(Date.now() + days * 864e5).toUTCString()
  document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=' + path
}

function getCookie(name) {
  return document.cookie.split('; ').reduce((r, v) => {
    let parts = v.split('=')
    return parts[0] === name ? decodeURIComponent(parts[1]) : r
  }, '');
}
