import { getArrayMax } from './utils';

export default function () {
  let carousel = document.querySelector('.js-carousel');
  let content = carousel.querySelector('.js-carousel-content');
  let items = carousel.querySelectorAll('.js-carousel-item');
  let page = 0;

  setSeperatorHeight();

  carousel
    .querySelector('.js-carousel-previous')
    .addEventListener('click', function () {
      if (page >= items.length - 1) {
        page = 0;
      } else {
        page++;
      }
      transition();
    });

  carousel
    .querySelector('.js-carousel-next')
    .addEventListener('click', function () {
      if (page === 0) {
        page = items.length - 1;
      } else {
        page--;
      }
      transition();
    });

  function transition() {
    content.style.setProperty(
      '--carousel-translate',
      `${content.offsetWidth * page}px`
    );
  }

  function setSeperatorHeight() {
    let descriptionDivs = Array.from(
      document.querySelectorAll('.carousel__description')
    );
    let heights = descriptionDivs.map((div) => div.clientHeight);
    let height = getArrayMax(heights);
    let seperatorDivs = Array.from(
      document.getElementsByClassName('carousel__seperator')
    );
    seperatorDivs.forEach((seperator) => {
      seperator.style.height = `${height}px`;
    });
  }
}
