export default function () {
  (function (gi, vi, n, g, l, oo, p) {
    (gi[l] =
      gi[l] ||
      function () {
        (gi[l].q = gi[l].q || []).push(arguments);
        gi.glObjName = l;
      }),
      (oo = vi.createElement(n)),
      (p = vi.getElementsByTagName(n)[0]);
    oo.async = 1;
    oo.src = g;
    oo.dataset.orgId = 1919;
    p.parentNode.insertBefore(oo, p);
    oo.id = 'gl-donation-popup-script';
  })(
    window,
    document,
    'script',
    'https://www.givingloop.org/js?' +
      Array(1)
        .fill(null)
        .map(() => Math.random().toString(20).substr(2))
        .join(''),
    'givingloop'
  );

 
  let frontPageMobile = document.querySelector('#donate-section');
  let fronPageDesktop = document.querySelector('#donate-container');
  let longRead = document.querySelector('#long-article');
  let oPed = document.querySelector('#oped');
  let menu = document.querySelector('.menu');
  let footer = document.querySelector('.footer__main');
  let about = document.querySelector('.about__support');
  let headerDonateMobile = document.querySelector('.donate-head-mobile');
  let headerDonateDesktop = document.querySelector('.donate-head-desktop');
  [
    frontPageMobile,
    fronPageDesktop,
    longRead,
    oPed,
    menu,
    footer,
    about,
    headerDonateMobile,
    headerDonateDesktop,
  ].forEach((target) => {
    if (target) {
      target.addEventListener('click', handleClick);
    }
  });


  function handleClick(e) {
    if (e.target.closest('.js-donate')) {
      e.preventDefault();
      window.open('https://donorbox.org/support-megaphone', '_blank');
    }
  }
}
