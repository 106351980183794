let timeout;

export default function () {
  setupPullovers();
  document.addEventListener('scroll', handleScroll, {
    passive: true,
  });
  setupUnblur();

  function handleScroll() {
    easeOutTableOfContents();
  }
}

function easeOutTableOfContents() {
  restartTocTimer();

  // Move TOC up with the notes list
  let notes = document.querySelector('.js-toc-stop');
  let container = document.querySelector('.js-sticky-toc-container');
  let notesListTop =
    notes.getBoundingClientRect().top -
    150 -
    document.querySelector('.js-toc-content').offsetHeight;

  container.style.setProperty(
    '--toc-sticky-top',
    `${Math.min(92, notesListTop)}px`
  );
}

function setupPullovers() {
  calculatePullOverSideLength();
  let mediaQuery = window.matchMedia('(max-width: 768px)');
  mediaQuery.addEventListener('change', calculatePullOverSideLength);
}

function calculatePullOverSideLength() {
  for (let pullover of document.querySelectorAll(
    '.long-content__pullover-shadow'
  )) {
    let parent = pullover.querySelector('.long-content__pullover');
    let child = pullover.querySelector('.js-text');
    let sideLength = 170;
    pullover.style.setProperty('--side-length', sideLength);

    while (child.offsetHeight > parent.offsetHeight && sideLength < 400) {
      sideLength += 10;
      pullover.style.setProperty('--side-length', sideLength);
    }
  }
}

function hideToc() {
  let shares = document.querySelectorAll('.js-share-checkbox');

  clearTimeout(timeout);
  timeout = setTimeout(() => {
    document
      .querySelector('.js-toc-ease-out')
      .classList.add('long-content__table-of-contents_is-hidden');

    for (let share of shares) {
      share.checked = false;
    }
  }, 4000);
}

function restartTocTimer() {
  document
    .querySelector('.js-toc-ease-out')
    .classList.remove('long-content__table-of-contents_is-hidden');
  hideToc();
}

function setupUnblur() {
  document.querySelectorAll('.blur-this').forEach((element) => {
    element.firstChild.addEventListener('click', function () {
      element.classList.toggle('blur-this');
    });
  });
}
