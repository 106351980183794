const uA = navigator.userAgent;
const vendor = navigator.vendor;
const isDesktopSafari = /Safari/i.test(uA) && /Apple Computer/.test(vendor) && !/Mobi|Android/i.test(uA);

export default function() {
  if (navigator.share && !isDesktopSafari) {
    for (let share of document.querySelectorAll('.js-share-btn')) {
      share.addEventListener('click', event => {
        event.preventDefault();
        navigator.share({
          title: event.target.getAttribute('data-title'),
          url: event.target.getAttribute('data-url'),
        });
      });
    }
  }
}
