import Choices from 'choices.js';

const choicesInstances = new Map(); // Create a map to store instances

export default function() {
  // Initialize Choices for all elements with the 'js-choice' class
  document.querySelectorAll('.js-choice').forEach(setup);
}

function setup(element) {
  let instance = new Choices(element, {
    placeholderValue: '',
    removeItemButton: true,
    allowHTML: true, // Explicitly allow HTML content within choice items
  });

  choicesInstances.set(element, instance); // Store the instance in the map

  if (element.value) {
    instance.containerInner.element.classList.add('choices__inner_is-selected');
  }

  element.addEventListener('change', e => {
    syncSelections(element, e.detail.value);
    if (e.detail.value) {
      instance.containerInner.element.classList.add('choices__inner_is-selected');
    } else {
      instance.containerInner.element.classList.remove('choices__inner_is-selected');
    }
  });
}

function syncSelections(sourceElement, selectedValue) {
  // Find the counterpart element (if source is mobile, find desktop, and vice versa)
  const counterpartSelector = sourceElement.classList.contains('js-choice-mobile')
    ? '.js-choice-desktop'
    : '.js-choice-mobile';
  const counterpartElement = document.querySelector(`${counterpartSelector}[name="${sourceElement.name}"]`);
  
  if (counterpartElement) {
    // Retrieve the counterpart instance from the map
    const counterpartInstance = choicesInstances.get(counterpartElement);
    if (counterpartInstance && counterpartElement.value !== selectedValue) {
      counterpartInstance.setChoiceByValue(selectedValue);
    }
  }
}
